<template>
  <div class="extra-tip">
    <template v-if="showInstallmentInfo">
      <div>
        <span>{{ frontListItemsValue.place_order_desc }}</span>
        <a
          v-show="frontListItemsValue.place_order_jump_url"
          :href="frontListItemsValue.place_order_jump_url"
          @click.stop
        >
          <i class="iconfont icon-pro"></i>
        </a>
      </div>
      <ItemInfoBnplGraph
        v-if="isShowBnplGraph && !isShowBnplBar"
        :scene="scene"
        :options="effectOptions"
      />
      <ItemInfoBnplBar
        v-else-if="isShowBnplBar"
        :scene="scene"
        :options="effectOptions"
      />
    </template>
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { template } from '@shein/common-function'
import ItemInfoBnplGraph from './item_info_bnpl_graph.vue'
import ItemInfoBnplBar from './item_info_bnpl_bar.vue'
import { daEventCenter } from 'public/src/services/eventCenter'
daEventCenter.addSubscriber({ modulecode: '2-31' })
import { exposeScenesAbtAggregator } from 'public/src/pages/checkout_new/utils/EventAggregator.js'

const paymentItemBnplColor = {
  afterpay: {
    color: '#68DCB6',
    undertone_color: '#DAF3F3'
  },
  klarna: {
    color: '#FFA8CD',
    undertone_color: '#FFE0ED'
  },
  other: {
    // color: '#0286C8',
    color: '#67BFFF',
    undertone_color: '#D2ECFF'
  }
}

export default defineComponent({
  name: 'ItemInfoBnpl',
  components: {
    ItemInfoBnplGraph,
    ItemInfoBnplBar
  },
  props: {
    scene: {
      type: String,
      default: 'checkout'
    },
    isSelect: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default() {
        return {}
      }
    },
    frontListItemsValue: {
      type: Object,
      default() {
        return {}
      }
    },
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    showInstallmentInfo() {
      return (
        this.item?.enabled == 1 && this.frontListItemsValue?.is_display == 1
      )
    },
    isShowBnplInfo() {
      return (
        this.isSelect &&
        this.showInstallmentInfo &&
        !this.displayIn30Days &&
        this.isOkRepaymentList &&
        this.isOkAmount
      )
    },
    isShowBnplGraph() {
      return this.isShowBnplInfo && this.options?.isShowBNPLinAbt
    },
    isShowBnplBar() {
      return this.isShowBnplInfo && this.options?.isShowNewBNPLBarAbt
    },
    isOkRepaymentList() {
      let graphInfo = this.frontListItemsValue.visualization
      if (!graphInfo || !graphInfo.repayment_period_list) return false
      // Bar 进度条只支持【2、3、4】3种分期
      const supportInstallments = [2, 3, 4]
      if (!this.options?.isShowNewBNPLBarAbt && this.options?.isShowBNPLinAbt) {
        // graph支持【2、3、4、5、6】5种分期
        supportInstallments.push(5, 6)
      }
      return supportInstallments.includes(graphInfo.repayment_period_list.length)
    },
    // 当【还款周期】=30且【还款周期单位】=day且【是否首期收费】=否时
    displayIn30Days() {
      let graphInfo = this.frontListItemsValue.visualization
      if (!graphInfo) return true
      return (
        graphInfo.repayment_period == 30 &&
        graphInfo.repayment_period_unit === 'day' &&
        graphInfo.first_period_repay === 0
      )
    },
    // 当总金额为0时不展示
    isOkAmount() {
      return !(+this.options.amount <= 0)
    },
    effectOptions() {
      let graphInfo = this.frontListItemsValue.visualization
      if (!graphInfo) return {}
      const { color } = paymentItemBnplColor[this.frontListItemsValue.bnpl_type]
      return {
        list: this.generateList(),
        color,
      }
    }
  },
  watch: {
    isSelect(val) {
      if (this.showInstallmentInfo && val) {
        this.reportExposeScenesabt()
        if (this.isShowBnplGraph || this.isShowBnplBar) {
          this.reportExposeBnplComponent()
        }
      }
    }
  },
  methods: {
    reportExposeScenesabt() {
      let graphInfo = this.frontListItemsValue.visualization
      exposeScenesAbtAggregator.addEvent({
        daId: '2-31-10-1',
        extraData: {
          scenes: 'bnpl_visual_component',
          if_show_bnpl_component: graphInfo ? '1' : '0'
        }
      })
    },
    reportExposeBnplComponent() {
      let graphInfo = this.frontListItemsValue.visualization
      daEventCenter.triggerNotice({
        daId: '2-31-10-2',
        extraData: {
          payment_method: this.item.code,
          installments_number: graphInfo.repayment_period_list?.length
        }
      })
    },
    pickLanguageTxt(num, unit) {
      switch (unit) {
        case 'day':
          return template(
            num,
            num === 1
              ? this.language.SHEIN_KEY_PWA_30879
              : this.language.SHEIN_KEY_PWA_30878
          )
        case 'week':
          return template(
            num,
            num === 1
              ? this.language.SHEIN_KEY_PWA_30881
              : this.language.SHEIN_KEY_PWA_30880
          )
        case 'month':
          return template(
            num,
            num === 1
              ? this.language.SHEIN_KEY_PWA_30882
              : this.language.SHEIN_KEY_PWA_30883
          )
      }
    },
    generateList() {
      let graphInfo = this.frontListItemsValue.visualization
      if (!graphInfo.repayment_period_list.length) return []

      let acc = 0
      let len = graphInfo.repayment_period_list.length
      const { color = '#67BFFF', undertone_color = '#D2ECFF' } = paymentItemBnplColor[this.frontListItemsValue.bnpl_type]
      return graphInfo.repayment_period_list.map((item, idx) => {

        let txt = this.language.SHEIN_KEY_PWA_30877
        let isStart = true
        if (idx) {
          isStart = false
          txt = this.pickLanguageTxt(
            (item.current_period - 1) * graphInfo.repayment_period,
            graphInfo.repayment_period_unit
          )
        }

        const listItemInfo = {
          amount: item.repayment_amount.amountWithSymbol,
          txt,
          isStart,
          isLast: idx === len - 1
        }

        if(!this.options?.isShowNewBNPLBarAbt){
          acc += item.repayment_amount.amount
          listItemInfo['percent'] = (acc / this.options.amount) * 100
        } else {
          listItemInfo['color'] = isStart ? color : undertone_color
        }
        
        return listItemInfo
      })
    }
  },
})
</script>
