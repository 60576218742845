<template>
  <div class="bnpl-ring">
    <div
      class="ring__progress"
      :style="{ clipPath }"
    ></div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    percent: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: '#FFA8CD'
    }
  },
  computed: {
    clipPath() {
      let deg = (this.percent / 100) * Math.PI * 2
      return `polygon${this.setPercentage(deg)}`
    }
  },
  methods: {
    setPercentage(deg) {
      const xLength = Math.sin(deg)
      const yLength = Math.cos(deg)
      const xPos = 1 + xLength
      const yPos = 1 - yLength
      if (deg <= Math.PI / 2) {
        return `(
                  50% 0%,
                  ${(xPos / 2) * 100}% 0%,
                  ${(xPos / 2) * 100}% ${(yPos / 2) * 100}%,
                  50% 50%
                )`
      } else if (deg > Math.PI / 2 && deg <= Math.PI) {
        return `(
                  50% 0%,
                  100% 0%,
                  100% ${(yPos / 2) * 100}%,
                  ${(xPos / 2) * 100}% ${(yPos / 2) * 100}%,
                  50% 50%
                )`
      } else if (deg > Math.PI && deg < (Math.PI / 2) * 3) {
        return `(
                ${(xPos / 2) * 100}% ${(yPos / 2) * 100}%,
                50% 50%,
                50% 0%,
                100% 0%,
                100% 100%,
                ${(xPos / 2) * 100}% 100%,
                ${(xPos / 2) * 100}% ${(yPos / 2) * 100}%,
                50% 50%
              )`
      } else if (deg >= (Math.PI / 2) * 3) {
        return `(
                  ${(xPos / 2) * 100}% ${(yPos / 2) * 100}%,
                  50% 50%,
                  50% 0%,
                  100% 0%,
                  100% 100%,
                  0% 100%,
                  0% ${(yPos / 2) * 100}%,
                  ${(xPos / 2) * 100}% ${(yPos / 2) * 100}%,
                  50% 50%
                )`
      }
    }
  },
})
</script>

<style lang="less" scoped>
.bnpl-ring {
  width: 30/37.5rem;
  height: 30/37.5rem;
  border-radius: 50%;
  background-color: #E5E5E5;
  text-align: center;
  position: relative;
  &::before {
    content: ' ';
    position: absolute;
    inset: 5/37.5rem;
    background-color: #fff;
    width: 20/37.5rem;
    height: 20/37.5rem;
    text-align: center;
    line-height: 20/37.5rem;
    border-radius: 50%;
  }
  .ring__progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 30/37.5rem;
    height: 30/37.5rem;
    border-radius: 50%;
    border: 5/37.5rem solid var(--bnpl_color);;
  }
}
</style>
